<template>
  <div class="bg-gray-100 min-h-screen">
    <div class="container mx-auto p-6 md:p-12">
      <header class="flex items-center justify-between mb-8">
        <h1 class="text-3xl font-semibold text-gray-800">我的账单</h1>
      </header>

      <div class="grid grid-cols-1 md:grid-cols-3 gap-6 mb-12">
        <div class="bg-white p-6 rounded-xl shadow-lg text-center transform transition duration-500 hover:scale-105">
          <h2 class="text-lg font-medium text-gray-600 mb-2">今日支出</h2>
          <p class="text-3xl font-bold text-red-500">¥{{ todayExpenseTotal }}</p>
        </div>
        <div class="bg-white p-6 rounded-xl shadow-lg text-center transform transition duration-500 hover:scale-105">
          <h2 class="text-lg font-medium text-gray-600 mb-2">本月支出</h2>
          <p class="text-3xl font-bold text-orange-500">¥{{ currentMonthStats.expense }}</p>
        </div>
        <div class="bg-white p-6 rounded-xl shadow-lg text-center transform transition duration-500 hover:scale-105">
          <h2 class="text-lg font-medium text-gray-600 mb-2">本月收入</h2>
          <p class="text-3xl font-bold text-green-500">¥{{ currentMonthStats.income }}</p>
        </div>
      </div>

      <div v-for="(entries, date) in sortedGroupedEntries" :key="date" class="mb-8">
      <h3 class="text-xl font-semibold text-gray-700 mb-4 text-left">{{ formatDate(date) }}</h3>
      <div class="bg-white rounded-xl shadow-lg overflow-hidden">
        <div class="p-6">
          <div class="space-y-4">
            <div v-for="entry in entries" :key="entry.id" 
                 @click="showEditEntryModal(entry)"
                 class="flex items-center justify-between py-3 border-b border-gray-200 last:border-b-0 cursor-pointer transition duration-300 ease-in-out">

                 <div class="flex items-center space-x-4">
                  <div class="flex items-center justify-center">
                    <span class="text-lg">{{ getEmoji(entry.category) }}</span>
                  </div>
                  <div>
                    <p class="text-lg font-medium text-gray-800">{{ entry.category }}</p>
                    <p class="text-sm text-gray-500">{{ entry.note }}</p>
                  </div>
                </div>
                <div class="text-right">
                  <p class="font-medium" :class="entry.type === 'income' ? 'text-green-500' : 'text-red-500'">
                    {{ entry.type === 'income' ? '+' : '-' }}¥{{ entry.amount }}
                  </p>
                  <p class="text-sm text-gray-500">{{ entry.type === 'income' ? '收入' : '支出' }}</p>
                </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    <add-entry-modal v-if="isAddEntryModalVisible" @close="isAddEntryModalVisible = false" @entry-added="addEntry"/>
    <edit-entry-modal v-if="isEditEntryModalVisible" 
                      :entry="selectedEntry" 
                      @close="isEditEntryModalVisible = false" 
                      @entry-updated="updateEntry"
                      @entry-deleted="deleteEntry"/>
  </div>
</template>

<script>
import AddEntryModal from '../components/AddEntryModal.vue';
import EditEntryModal from '../components/EditEntryModal.vue';
import { getEmoji } from '@/utils/util.js' 

export default {
  components: {
    AddEntryModal,
    EditEntryModal,
  },
  data() {
    return {
      isAddEntryModalVisible: false,
      filterDate: '',
      isEditEntryModalVisible: false,
      selectedEntry: null,
    };
  },
  computed: {
    currentMonthStats() {
      const now = new Date();
      const currentYear = now.getFullYear();
      const currentMonth = now.getMonth();
      
      const currentMonthEntries = this.$store.state.entries.filter(entry => {
        const entryDate = new Date(entry.date);
        return entryDate.getFullYear() === currentYear && entryDate.getMonth() === currentMonth;
      });

      const income = currentMonthEntries
        .filter(entry => entry.type === 'income')
        .reduce((sum, entry) => sum + parseFloat(entry.amount), 0);
      
      const expense = currentMonthEntries
        .filter(entry => entry.type === 'expense')
        .reduce((sum, entry) => sum + parseFloat(entry.amount), 0);

      return {
        income: income.toFixed(2),
        expense: expense.toFixed(2),
        balance: (income - expense).toFixed(2)
      };
    },

    todayExpenseTotal() {
      const today = new Date().toISOString().split('T')[0];
      return this.$store.state.entries
        .filter(entry => entry.type === 'expense' && entry.date === today)
        .reduce((total, entry) => total + parseFloat(entry.amount), 0)
        .toFixed(2);
    },
    balance() {
      const income = this.$store.state.entries
        .filter(entry => entry.type === 'income')
        .reduce((total, entry) => total + parseFloat(entry.amount), 0);
      const expense = this.$store.state.entries
        .filter(entry => entry.type === 'expense')
        .reduce((total, entry) => total + parseFloat(entry.amount), 0);
      return (income - expense).toFixed(2);
    },
    groupedEntries() {
      const filteredEntries = this.filterDate
        ? this.$store.state.entries.filter(entry => entry.date.startsWith(this.filterDate))
        : this.$store.state.entries;
      return filteredEntries.reduce((acc, entry) => {
        const date = entry.date;
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(entry);
        return acc;
      }, {});
    },
    sortedGroupedEntries() {
      const groupedEntries = this.groupedEntries;
      return Object.keys(groupedEntries)
        .sort((a, b) => new Date(b) - new Date(a))
        .reduce((acc, key) => {
          acc[key] = groupedEntries[key];
          return acc;
        }, {});
    },
  },
  methods: {
    showAddEntryModal() {
      this.isAddEntryModalVisible = true;
    },
    addEntry(entry) {
      if (entry && entry.type && entry.amount && entry.date && entry.category) {
        this.$store.commit('addEntry', entry);
      } else {
        console.error('无效的记账条目', entry);
      }
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString('zh-CN', { year: 'numeric', month: 'long', day: 'numeric'});
    },
    getEmoji,
    showEditEntryModal(entry) {
      this.selectedEntry = { ...entry };
      this.isEditEntryModalVisible = true;
    },
    updateEntry(updatedEntry) {
      this.$store.commit('updateEntry', updatedEntry);
      this.isEditEntryModalVisible = false;
    },
    deleteEntry(entryId) {
      this.$store.commit('deleteEntry', entryId);
      this.isEditEntryModalVisible = false;
    },
  },
};
</script>

<style scoped>
.container {
  width: 100%;
  max-width: 1200px;
}

button {
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: rgb(29 78 216);
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal form {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  max-width: 500px;
}

.mb-8 {
  margin-bottom: 2rem;
}

</style>
