<template>
  <div id="app" class="app-container">
    <div class="content-container">
      <router-view></router-view>
    </div>
    <NavBar @open-add-entry="openAddEntryModal" />
    <AddEntryModal v-if="showAddEntryModal" @close="closeAddEntryModal" />
  </div>
</template>

<script>
import NavBar from './components/Navbar.vue';
import AddEntryModal from './components/AddEntryModal.vue'

export default {
  name: "App",
  components: {
    NavBar,
    AddEntryModal
  },
  data() {
    return {
      showAddEntryModal: false
    }
  },
  methods: {
    openAddEntryModal() {
      // 在这里实现打开添加记账条目弹窗的逻辑
      this.showAddEntryModal = true;
    },
    closeAddEntryModal() {
      this.showAddEntryModal = false;
    },
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.content-container {
  flex-grow: 1;
  background-color: #f7fafc;
  overflow-y: auto;
  margin-bottom: 64px;
}

/* 如果你想保持文本居中对齐，可以在具体的组件中设置 */
.content-container > * {
  text-align: center;
}
</style>
