import { createStore } from 'vuex';
import dayjs from 'dayjs';

// LocalStorage Plugin
const localStoragePlugin = store => {
  store.subscribe((mutation, state) => {
    localStorage.setItem('vuex-entries', JSON.stringify(state.entries));
  });
};

// Load initial state from localStorage if available
const savedEntries = JSON.parse(localStorage.getItem('vuex-entries')) || [];

export default createStore({
  state: {
    entries: savedEntries
  },
  mutations: {
    addEntry(state, entry) {
      state.entries.push(entry);
    },
    editEntry(state, updatedEntry) {
      const index = state.entries.findIndex(entry => entry.id === updatedEntry.id);
      if (index !== -1) {
        state.entries.splice(index, 1, updatedEntry);
      }
    },
    deleteEntry(state, entryId) {
      state.entries = state.entries.filter(entry => entry.id !== entryId);
    }
  },
  actions: {
    addEntry({ commit }, entry) {
      commit('addEntry', entry);
    },
    editEntry({ commit }, updatedEntry) {
      commit('editEntry', updatedEntry);
    },
    deleteEntry({ commit }, entryId) {
      commit('deleteEntry', entryId);
    }
  },
  // getters: {
  //   getEntries: state => state.entries,
  //   getRecentEntries: state => state.entries.slice().sort((a, b) => b.id - a.id).slice(0, 5),
  //   getMonthlyStatistics: state => {
  //     const currentMonth = dayjs().month();
  //     const monthlyEntries = state.entries.filter(entry => dayjs(entry.id).month() === currentMonth);
  //     const totalAmount = monthlyEntries.reduce((sum, entry) => sum + parseFloat(entry.amount), 0);
  //     return { totalAmount, count: monthlyEntries.length };
  //   }
  // },

  getters: {
    getEntries: state => state.entries,
    getRecentEntries: state => {
      return state.entries
        .slice()
        .sort((a, b) => dayjs(b.date).valueOf() - dayjs(a.date).valueOf())
        .slice(0, 5);
    },
    getMonthlyStatistics: state => {
      const currentMonth = dayjs().month();
      const currentYear = dayjs().year();
      const monthlyEntries = state.entries.filter(entry => {
        const entryDate = dayjs(entry.date);
        return entryDate.month() === currentMonth && entryDate.year() === currentYear;
      });
      
      const income = monthlyEntries
        .filter(entry => entry.type === 'income')
        .reduce((sum, entry) => sum + parseFloat(entry.amount), 0);
      
      const expense = monthlyEntries
        .filter(entry => entry.type === 'expense')
        .reduce((sum, entry) => sum + parseFloat(entry.amount), 0);
      
      const balance = income - expense;
      
      return { income, expense, balance };
    }
  },

  modules: {},
  plugins: [localStoragePlugin]
});
